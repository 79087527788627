'use strict';

const $body = $('body');
const formValidation = require('core/components/formValidation');
const clientSideValidation = require('core/components/clientSideValidation');

const $registrationForm = $('form.registration');

module.exports = {
  init: function () {
    this.initAgeValidation();
    //  clientSideValidation.init();
    $registrationForm.on('bouncerFormValid', function (e) {
      e.preventDefault();

      $.spinner().start();
      clientSideValidation.functions.clearFormErrors($registrationForm);

      $.post({
        url: $registrationForm.attr('action'),
        dataType: 'json',
        data: $registrationForm.serialize(),
        success: function (data) {
          if (!data.success) {
            formValidation($registrationForm, data);
            $.spinner().stop();
            clientSideValidation.functions.scrollToError($registrationForm);
          } else {
            $body.trigger('user:registered');
            $body.trigger('customer:updateAttributes');
            window.location = data.redirectUrl;
          }
        },
        error: function (err) {
          if (err.responseJSON.redirectUrl) {
            window.location.href = err.responseJSON.redirectUrl;
          }

          $.spinner().stop();
        },
      });

      return false;
    });

    /**
     * toggle confirmation content
     */
    $('.js_toggle-order-details').on('click', function () {
      $('.js_confirmation-content').slideToggle();
      $(this).toggleClass('active');
    });
  },
  initAgeValidation: function() {
    const $birthdateInput = $('#registration-form-birthday');
    const today = new Date();
    const maxDate = new Date(
        today.getFullYear() - 18,
        today.getMonth(),
        today.getDate()
    );

    $birthdateInput.attr('max', maxDate.toISOString().split('T')[0]);
  },

  handlePopups: function () {
    $('#privacyPopupModal').on('shown.bs.modal', function (event) {
      const $aTag = $(event.relatedTarget); // Element that triggered the modal
      const $relevantSelector = $($aTag.data('hash'));

      if ($relevantSelector.length) {
        $relevantSelector.get(0).scrollIntoView();
      }
    });
  },
  datePickerKeyboardNav: function () {
    $('#registration-form-birthday').on('keydown', function (event) {
      if (event.key !== 'Enter' || this !== document.activeElement) return; // Only do something if element has focus and Enter is pressed.

      const $datepicker = $('.datepicker');
      if (!$datepicker.length) return; // date picker not open

      // get current user selection
      const $selection = $datepicker
        .children()
        .filter(function () {
          return $(this).css('display') !== 'none';
        })
        .find('.focused');
      // click on user selection
      $selection.trigger('click');

      event.preventDefault();
      event.stopImmediatePropagation();
    });
  },
  passwordDinamicValidation: function() {
    $('#registration-form-password').on('keydown input', function(e) {
        var emailInput = e.target.value;
        if(emailInput.length >= 8) {
          $('.error-length').addClass('green');
        } else {
          $('.error-length').removeClass('green');
        } 
        if(/\d/.test(emailInput)) {
          $('.error-number').addClass('green');
        } else {
          $('.error-number').removeClass('green');
        }
        if( /[!+,\-./:;<=>?@]/.test(emailInput)) {
          $('.error-character').addClass('green');
        } else {
          $('.error-character').removeClass('green');
        }
    });
  }
};
