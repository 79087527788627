'use strict';

const formValidation = require('core/components/formValidation');
const clientSideValidation = require('core/components/clientSideValidation');
const addToCartValidation = require('core/product/addToCartValidation');
const $body = $('body');
const showOnModal = document.querySelector('body').classList.contains('Margiela') || document.querySelector('body').classList.contains('JilSander');

module.exports = {
  init: function () {
    clientSideValidation.init();
    initNotifyReCaptcha();

    $body.on('click', '.js_product-notify-open', function () {
      $(this).parent().remove();
      $('.product-notify-body').show();
    });

    $body.on('submit', '.js_notify-when-available', function (e) {
      const $form = $(this);
      e.preventDefault();
      $.spinner().start();
      clientSideValidation.functions.clearFormErrors($form);
      $('.product-notify-content .success-message').removeClass('success');

      if(showOnModal && !$form.find('[type="checkbox"][required="required"]').prop('checked')) {
        $form.find('[type="checkbox"][required="required"]').addClass('-invalid');
        return;
      }
      
      var isNewPDP = false;
    
      if ($('.pdp-new').length > 0) {
        isNewPDP = true;
      }

      // Check if all variation attribute selected
      let $productContainer = $(this).closest('.product-detail');
      if (addToCartValidation($productContainer) || isNewPDP) {
        $.post({
          url: $form.attr('action'),
          dataType: 'json',
          data: $form.serialize(),
          success: function (data) {
            if (!data.success) {
              formValidation($form, data);
            } else {
              if (!isNewPDP) {
                $('.product-notify-content .success-message').addClass('success');
              } else {

                $('.product-notify-header .title').addClass('d-none');
                $('.product-notify-header .title-success').removeClass('d-none');
                $('.product-notify-content .product-coming-soon').addClass('d-none');
                $('.notify-message-success .user-email').text(data.email + ' ');
                $('.product-notify-content .product-notify-content-success').addClass('success');
              }
              
              //Tealium trigger "Back In Stock"
              $('body').trigger('product:afterBackInStock');
              if(showOnModal) {
                $('body').trigger('product:backInStockComplete');
                document.querySelector('#notify-me-modal .modal-close').click();

                // show notification
                const event = new CustomEvent('notification:show', {
                  detail: { 
                    message: data.message,
                    icon: true,
                    link: false
                  }
                });
                document.body.dispatchEvent(event);
              }
            };   
          },
          complete: function () {
            $.spinner().stop();
          },
        });
      } else {
        $.spinner().stop();
      }

      return false;
    });
  },
  updateForm: function () {
    $('body').on('product:updateAvailability', function (e, response) {
      var $container;
      
      var isNewPDP = false;
    
      if ($('.pdp-new').length > 0) {
        isNewPDP = true;
      }

      if (!isNewPDP) {
        $container = showOnModal ? $('.product-notify .modal-content') : $('.product-notify');
      } else if (isNewPDP && $('.notify-size.is-open').length > 0) {
        $container = $('.notify-size.is-open .product-notify-content .product-coming-soon.coming-soon');
      } else {
        $container = $('.product-notify');
      }
      if ($container.length > 0) {
        
        if (isNewPDP && $('.notify-size.is-open').length > 0) {
          $container.empty().append(response.product.notifyWhenAvailableHtml);
          $('.notify-size.is-open .product-notify-content .product-coming-soon').removeClass('d-none');
          $('.notify-size.is-open .product-notify-header .title').removeClass('d-none');
          $('.notify-size.is-open .product-notify-header .title-success').addClass('d-none');
          $('.notify-size.is-open .product-notify-content .product-notify-content-success').removeClass('success');
        } else if (isNewPDP && $('.notify-size.is-open').length == 0) {
          $container.empty().append(response.product.notifyWhenAvailablePopUpHtml);
          $('.product-notify .product-notify-content .product-coming-soon').removeClass('d-none');
          $('.product-notify .product-notify-header .title').removeClass('d-none');
          $('.product-notify .product-notify-header .title-success').addClass('d-none');
          $('.product-notify .product-notify-content .product-notify-content-success').removeClass('success');
        } else {
          $container.empty().append(response.product.notifyWhenAvailableHtml);
        }

        const $form = $container.find('form');
        if(!showOnModal) {
          if ($('.layer-product-notify.notify-size.is-open').length < 1) {
            if ($form.length > 0) {
              $('#add-to-cart-wishlist-wrapper').hide();
              clientSideValidation.functions.reAttachNoBubbleEvents();
            } else {
              $('#add-to-cart-wishlist-wrapper').show();
            }
          }
        }
      }

      initNotifyReCaptcha();
    });
  },
};

function initNotifyReCaptcha() {
  let $recaptchaInput = $('.js_notify-when-available #gReCaptchaWidgetNotify');
  if ($recaptchaInput.length > 0) {
    grecaptcha.ready(function() {
      grecaptcha.execute($recaptchaInput.data('sitekey'), { action: 'init' }).then(function(token) {
        $recaptchaInput.val(token);
      });
    });
  }
}
