'use strict';

import { Datepicker } from 'vanillajs-datepicker'; 

const inputElement = document.querySelector('.form-control__input--date');

if ($(inputElement).length > 0) {
  const datepickerDefault = {
    autohide: true,
    startView: 2
  };
  if (inputElement.dataset.milliseconds) {
    const now = new Date();
    const nowTime = new Date(now.getTime() - Number(inputElement.dataset.milliseconds));
    datepickerDefault.maxDate = nowTime;
    delete inputElement.dataset.milliseconds;
  }
  const currentConfig = { ...datepickerDefault, ...inputElement.dataset };
  inputElement.datepicker = new Datepicker(inputElement, currentConfig);
}