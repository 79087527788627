'use strict';

const processInclude = require('base/util');

$(document).ready(function () {
  processInclude(require('./registration/registration'));
  processInclude(require('core/datepicker'));
  processInclude(require('tealium/tealiumEvents'));
});
require('core/components/spinner');
